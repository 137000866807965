import {action} from 'typesafe-actions';
import ActionTypes from './constants';
import {Vehicle} from '../models/Vehicle';
import {User} from '../models/User';
import {Customer} from '../models/Customer';
import {IVehicleTail} from '../models/IVehicleTail';
import {Tag} from '../models/Tag';
import {IAddress} from '../models/IAddress';
import {IVehicleTrack} from '../models/IVehicleTrack';
import {ITrackParams} from '../models/ITrackParams';
import {ILanguage} from '../models/ILanguage';
import {Geofence} from '../models/Geofence';
import {IPosition} from '../models/IPosition';
import {Driver} from "../models/Driver";
import {DrawModeEnum} from "../enums/DrawModeEnum";
import { ScrollableTypeEnum } from "../enums/ScrollableTypeEnum";

export const clearGlobalState = () =>
    action(ActionTypes.CLEAR_GLOBAL_STATE);

export const setLanguages = (languages: ILanguage []) =>
    action(ActionTypes.SET_LANGUAGES, languages);

export const getLanguages = () =>
    action(ActionTypes.GET_LANGUAGES);

export const setUserLoginStatus = (USER_LOGIN_STATUS: number) =>
    action(ActionTypes.SET_USER_LOGIN_STATUS, USER_LOGIN_STATUS);

export const setServerConnectionStatus = (SERVER_CONNECTION_STATUS: number) =>
    action(ActionTypes.SET_SERVER_CONNECTION_STATUS, SERVER_CONNECTION_STATUS);

export const setInitServerConnection = (INIT_SERVER_CONNECTION: boolean) =>
    action(ActionTypes.SET_INIT_SERVER_CONNECTION, INIT_SERVER_CONNECTION);

export const sseSubscribe = () =>
    action(ActionTypes.SSE_SUBSCRIBE);

export const setCurrentUser = (currentUser: User | undefined) =>
    action(ActionTypes.SET_CURRENT_USER, currentUser);

export const setCurrentUserGeolocation = (currentUserGeolocation: any | undefined) =>
    action(ActionTypes.SET_CURRENT_USER_GEOLOCATION, currentUserGeolocation);

export const getCurrentUser = () =>
    action(ActionTypes.GET_CURRENT_USER);

export const updateCurrentUser = () =>
    action(ActionTypes.UPDATE_CURRENT_USER);

// export const updateCurrentUserImage = (imageData: any) =>
//     action(ActionTypes.UPDATE_CURRENT_USER_IMAGE, imageData);

export const updateCurrentUserByAttribute = (attrData: any) =>
    action(ActionTypes.UPDATE_CURRENT_USER_BY_ATTRIBUTE, attrData);

export const setCurrentCustomer = (currentCustomer: Customer | undefined) =>
    action(ActionTypes.SET_CURRENT_CUSTOMER, currentCustomer);

export const getCurrentCustomer = () =>
    action(ActionTypes.GET_CURRENT_CUSTOMER);

export const updateCurrentCustomer = () =>
    action(ActionTypes.UPDATE_CURRENT_CUSTOMER);

export const updateCurrentCustomerByAttribute = (attrData: any) =>
    action(ActionTypes.UPDATE_CURRENT_CUSTOMER_BY_ATTRIBUTE, attrData);

export const getAccessibleCustomers = () =>
    action(ActionTypes.GET_ACCESSIBLE_CUSTOMERS);

export const setAccessibleCustomers = (accessibleCustomers: Customer [] | []) =>
    action(ActionTypes.SET_ACCESSIBLE_CUSTOMERS, accessibleCustomers);

export const getVehicleList = () =>
    action(ActionTypes.GET_VEHICLE_LIST);

export const setVehicleList = (vehicleList: Vehicle []) =>
    action(ActionTypes.SET_VEHICLE_LIST, vehicleList);

export const updateVehicle = (vehicle: Vehicle) =>
    action(ActionTypes.UPDATE_VEHICLE, vehicle);

export const updateVehicles = (vehicles: Vehicle []) =>
    action(ActionTypes.UPDATE_VEHICLES, vehicles);

// export const updateVehicleList = (vehicleList: Vehicle []) =>
//     action(ActionTypes.UPDATE_VEHICLE_LIST, vehicleList);

export const setVehicleListParams = (vehicleListParams: any) =>
    action(ActionTypes.SET_VEHICLE_LIST_PARAMS, vehicleListParams);

export const setVehicleId = (vehicleId: number | undefined) =>
    action(ActionTypes.SET_VEHICLE_ID, vehicleId);

export const setVehicleTailList = (vehicleTailList: IVehicleTail []) =>
    action(ActionTypes.SET_VEHICLES_TAILS, vehicleTailList);

export const setVehicle = (vehicle: Vehicle | undefined) =>
    action(ActionTypes.SET_VEHICLE, vehicle);

export const setSwipedVehicle = (vehicle: Vehicle | undefined) =>
    action(ActionTypes.SET_SWIPED_VEHICLE, vehicle);

export const setVehicleDrivers = (vehicleDriverList: Driver [] | []) =>
    action(ActionTypes.SET_VEHICLE_DRIVERS, vehicleDriverList);

export const getVehicleDrivers = (params: {withPhoto: boolean}) =>
    action(ActionTypes.GET_VEHICLE_DRIVERS, params);

export const getTagList = () =>
    action(ActionTypes.GET_TAG_LIST);

export const setTagList = (tagList: Tag []) =>
    action(ActionTypes.SET_TAG_LIST, tagList);

export const setVehicleTagParams = (vehicleTagParams: any) =>
    action(ActionTypes.SET_VEHICLE_TAG_PARAMS, vehicleTagParams);

export const attachTag = () =>
    action(ActionTypes.ATTACH_TAG);

export const detachTag = () =>
    action(ActionTypes.DETACH_TAG);

export const reattachTag = () =>
    action(ActionTypes.REATTACH_TAG);

export const setTag = (tag: Tag | undefined) =>
    action(ActionTypes.SET_TAG, tag);

export const setDeleteTagId = (tagId: number | undefined) =>
    action(ActionTypes.SET_DELETE_TAG_ID, tagId);

export const createTag = () =>
    action(ActionTypes.CREATE_TAG);

export const updateTag = () =>
    action(ActionTypes.UPDATE_TAG);

export const deleteTag = () =>
    action(ActionTypes.DELETE_TAG);

export const setIsOpenTags = (isOpenTags: boolean) =>
    action(ActionTypes.SET_IS_OPEN_TAGS, isOpenTags);

export const setIsOpenGroups = (isOpenGroups: boolean) =>
    action(ActionTypes.SET_IS_OPEN_GROUPS, isOpenGroups);

export const setLoading = (loading: boolean) =>
    action(ActionTypes.SET_LOADING, loading);

export const setFreeze = (freeze: boolean) =>
    action(ActionTypes.SET_FREEZE, freeze);

export const setCentered = (centered: boolean) =>
    action(ActionTypes.SET_CENTERED, centered);

export const userLogout = () =>
    action(ActionTypes.USER_LOGOUT);

export const setExpand = (expand: boolean) =>
    action(ActionTypes.SET_EXPAND, expand);

export const getVehicleAddress = () =>
    action(ActionTypes.GET_VEHICLE_ADDRESS);

export const setVehicleAddress = (vehicleAddress: IAddress | undefined) =>
    action(ActionTypes.SET_VEHICLE_ADDRESS, vehicleAddress);

export const setVehicleCurrentPosition = (vehicleCurrentPosition: number | undefined) =>
    action(ActionTypes.SET_VEHICLE_CURRENT_POSITION, vehicleCurrentPosition);

export const setTrackUUID = (trackUUID: string | undefined) =>
    action(ActionTypes.SET_TRACK_UUID, trackUUID);

export const setVehiclesTracks = (vehiclesTracks: IVehicleTrack[]) =>
    action(ActionTypes.SET_VEHICLES_TRACKS, vehiclesTracks);

export const setTrackParams = (trackParams: ITrackParams | undefined) =>
    action(ActionTypes.SET_TRACK_PARAMS, trackParams);

export const getVehicleTrack = () =>
    action(ActionTypes.GET_VEHICLE_TRACK);

export const setEmptyTrack = (emptyTrack: boolean) =>
    action(ActionTypes.SET_EMPTY_TRACK, emptyTrack);

export const setVisibleStream = (visibleStream: boolean) =>
    action(ActionTypes.SET_VISIBLE_STREAM, visibleStream);

export const setVisibleMonitoringInfo = (visibleMonitoringInfo: boolean) =>
    action(ActionTypes.SET_VISIBLE_MONITORING_INFO, visibleMonitoringInfo);

export const setVehicleAlarmStatus = () =>
    action(ActionTypes.SET_VEHICLE_ALARM_STATUS);

export const setLastPingTimestamp = (lastPingTimestamp: number | undefined) =>
    action(ActionTypes.SET_LAST_PING_TIMESTAMP, lastPingTimestamp);

export const setImportProgress = (importProgress: any) =>
    action(ActionTypes.SET_IMPORT_PROGRESS, importProgress);

export const getGeofenceList = () =>
    action(ActionTypes.GET_GEOFENCE_LIST);

export const setGeofenceList = (geofenceList: Geofence []) =>
    action(ActionTypes.SET_GEOFENCE_LIST, geofenceList);

export const updateGeofence = (geofence: Geofence) =>
    action(ActionTypes.UPDATE_GEOFENCE, geofence);

export const setGeofenceListParams = (geofenceListParams: any) =>
    action(ActionTypes.SET_GEOFENCE_LIST_PARAMS, geofenceListParams);

export const setGeofenceId = (geofenceId: number | undefined) =>
    action(ActionTypes.SET_GEOFENCE_ID, geofenceId);

export const setGeofence = (geofence: Geofence | undefined) =>
    action(ActionTypes.SET_GEOFENCE, geofence);

export const updateGeofenceByAttr = (data: any) =>
    action(ActionTypes.UPDATE_GEOFENCE_BY_ATTRIBUTE, data);

export const setGeofenceCRUDMode = (geofence_CRUD_Mode: string | undefined) =>
    action(ActionTypes.SET_GEOFENCE_CRUD_MODE, geofence_CRUD_Mode);

export const createUpdateGeofence = () =>
    action(ActionTypes.CREATE_UPDATE_GEOFENCE);

export const setStartDrawGeofence = (startDrawGeofence: {type: string | undefined, color: string | undefined} | undefined) =>
    action(ActionTypes.START_DRAW_GEOFENCE, startDrawGeofence);

export const setVisibleGeofenceInfo = (visibleGeofenceInfo: boolean) =>
    action(ActionTypes.SET_VISIBLE_GEOFENCE_INFO, visibleGeofenceInfo);

export const attachDetachVehiclesToGeofence = () =>
    action (ActionTypes.ATTACH_DETACH_VEHICLES_TO_GEOFENCE);

export const getVehicleGeofenceList = () =>
    action(ActionTypes.GET_VEHICLE_GEOFENCE_LIST);

export const updateVehicleGeofence = (vehicle: Vehicle) =>
    action(ActionTypes.UPDATE_VEHICLE_GEOFENCE, vehicle);

export const updateVehiclesGeofence = (vehicles: Vehicle []) =>
    action(ActionTypes.UPDATE_VEHICLES_GEOFENCE, vehicles);

export const setVehicleGeofenceList = (vehicleGeofenceList: Vehicle []) =>
    action(ActionTypes.SET_VEHICLE_GEOFENCE_LIST, vehicleGeofenceList);

export const deleteGeofence = () =>
    action(ActionTypes.DELETE_GEOFENCE);

export const startEditGeofenceById = (editGeofenceId: number | undefined) =>
    action(ActionTypes.START_EDIT_GEOFENCE_BY_ID, editGeofenceId);

export const endEditGeofence = (isEditGeofence: boolean) =>
    action(ActionTypes.END_EDIT_GEOFENCE, isEditGeofence);

export const setVehicleListAttachedToGeofence = (vehicleListAttachedToGeofence: Vehicle []) =>
    action(ActionTypes.SET_VEHICLE_LIST_ATTACHED_TO_GEOFENCE, vehicleListAttachedToGeofence);

export const getVehicleListAttachedToGeofence = () =>
    action(ActionTypes.GET_VEHICLE_LIST_ATTACHED_TO_GEOFENCE);

export const setVehicleAmountInsideOutsideToGeofence = (vehicleAmountInsideOutsideToGeofence: {id: number, inside: number, outside: number}) =>
    action(ActionTypes.SET_VEHICLE_AMOUNT_INSIDE_OUTSIDE_TO_GEOFENCE, vehicleAmountInsideOutsideToGeofence);

export const getVehicleAmountInsideOutsideToGeofence = (geofence: {id: number}) =>
    action(ActionTypes.GET_VEHICLE_AMOUNT_INSIDE_OUTSIDE_TO_GEOFENCE, geofence);

export const setEditedGeofenceRadius = (editedGeofenceRadius: number | undefined) =>
    action(ActionTypes.SET_EDITED_GEOFENCE_CIRCLE_RADIUS, editedGeofenceRadius);

export const getCheckedVehiclesPositions = () =>
    action(ActionTypes.GET_CHECKED_VEHICLES_POSITIONS);

export const setCheckedVehiclesPositions = (checkedVehiclesPositions: IPosition []) =>
    action(ActionTypes.SET_CHECKED_VEHICLES_POSITIONS, checkedVehiclesPositions);

export const setCheckedVehiclesPositionsParams = (checkedVehiclesPositionsParams: any) =>
    action(ActionTypes.SET_CHECKED_VEHICLES_POSITIONS_PARAMS, checkedVehiclesPositionsParams);

export const setLoadingVehiclesPosition = (loadingVehiclesPosition: boolean) =>
    action(ActionTypes.SET_LOADING_VEHICLES_POSITIONS, loadingVehiclesPosition);

export const setVehicleSensors = (vehicleSensors: any) =>
    action(ActionTypes.SET_VEHICLE_SENSORS, vehicleSensors);

export const setVehicleSensorsById = (vehicleSensors: any) =>
    action(ActionTypes.SET_VEHICLE_SENSORS_BY_ID, vehicleSensors);

export const getVehicleSensors = () =>
    action(ActionTypes.GET_VEHICLE_SENSORS);

export const getVehicleSensorsById = (sensor: {id: number}) =>
    action(ActionTypes.GET_VEHICLE_SENSORS_BY_ID, sensor);

export const setVisibleTrackingInfo = (visibleTrackingInfo: boolean) =>
    action(ActionTypes.SET_VISIBLE_TRACKING_INFO, visibleTrackingInfo);

export const setTrackSegmentUUID = (trackSegmentUUID: string | undefined) =>
    action(ActionTypes.SET_TRACK_SEGMENT_UUID, trackSegmentUUID);

export const setTrackSegmentEvent = (trackSegmentEvent: any | undefined) =>
    action(ActionTypes.SET_TRACK_SEGMENT_EVENT, trackSegmentEvent);

export const setFollowVehicleId = (followVehicleId: number | undefined) =>
    action(ActionTypes.SET_FOLLOW_VEHICLE_ID, followVehicleId);

export const setVisibleGeozonesLayer = (isVisibleGeozonesLayer: boolean) =>
    action(ActionTypes.SET_VISIBLE_GEOZONES_LAYER, isVisibleGeozonesLayer);

export const setVisibleVehicleList = (visibleVehicleList: boolean) =>
    action(ActionTypes.SET_VISIBLE_VEHICLE_LIST, visibleVehicleList);

export const setVisibleMobileMenuBtn = (visibleMobileMenuBtn: boolean) =>
    action(ActionTypes.SET_VISIBLE_MOBILE_MENU_BTN, visibleMobileMenuBtn);

export const setCRUDMode = (CRUD_Mode: number | undefined) =>
    action(ActionTypes.SET_CRUD_MODE, CRUD_Mode);

export const getVehiclePositionById = (data: any) =>
    action(ActionTypes.GET_POSITION_BY_ID, data);

export const getVehiclePositionByTimestamp = (data: any) =>
    action(ActionTypes.GET_POSITION_BY_TIMESTAMP, data);

export const setVehiclePosition = (position: any) =>
    action(ActionTypes.SET_POSITION, position);

export const setRawTrackForce = (rawTrackForce: boolean) =>
    action(ActionTypes.SET_RAW_TRACK_FORCE, rawTrackForce);

export const setRawTrackPosition = (rawTrackPosition: IPosition | undefined) =>
    action(ActionTypes.SET_RAW_TRACK_POSITION, rawTrackPosition);

export const setGeofenceEditableLayer = (geofenceEditableLayer: any) =>
    action(ActionTypes.SET_GEOFENCE_EDITABLE_LAYER, geofenceEditableLayer);

export const importVehicles = (data: {type: string, target: string, file: any, vehicle?: Vehicle}) =>
    action(ActionTypes.IMPORT_VEHICLES_POSITIONS, data);

export const setLoadingFile = (loadingFile: boolean) =>
    action(ActionTypes.LOADING_FILE, loadingFile);

export const setLoadingFileName = (loadingFileName: string | undefined) =>
    action(ActionTypes.LOADING_FILE_NAME, loadingFileName);

export const setOpenImportList = (openImportList: boolean) =>
    action(ActionTypes.OPEN_IMPORT_LIST, openImportList);

export const setImportListInProgress = (importListInProgress: any []) =>
    action(ActionTypes.SET_IMPORT_LIST_IN_PROGRESS, importListInProgress);

export const setImportHistory = (importHistory: any []) =>
    action(ActionTypes.SET_IMPORT_HISTORY, importHistory);

export const getImportHistory = (query: any) =>
    action(ActionTypes.GET_IMPORT_HISTORY, query);

export const setDrawMode = (drawMode: DrawModeEnum) =>
    action(ActionTypes.SET_DRAW_MODE, drawMode);

export const saveImage = (data: any) =>
    action(ActionTypes.SAVE_IMAGE, data);

export const setScrollPosition = (scrollPosition: {position: number, type: ScrollableTypeEnum}) =>
    action(ActionTypes.SET_SCROLL_POSITION, scrollPosition);

export const exportGeofences = (data: {ids: number [] | undefined}) =>
    action(ActionTypes.EXPORT_GEOFENCES, data);

export const importGeofences = (data: {type: string, target: string, file: any}) =>
    action(ActionTypes.IMPORT_GEOFENCES, data);

export const setPendingRefreshToken = (pendingRefreshToken: boolean) =>
    action(ActionTypes.SET_PENDING_REFRESH_TOKEN, pendingRefreshToken);

export const loginToClient = (data: any) =>
    action(ActionTypes.LOGIN_TO_CLIENT, data);

export const setLastSelectedVehicleId = (id: number | undefined) =>
    action(ActionTypes.SET_LAST_SELECTED_VEHICLE_ID, id);

export const setLastSelectedVehicleState = (value: boolean) =>
    action(ActionTypes.SET_LAST_SELECTED_VEHICLE_STATE, value);

export const setIsEditTrack = (value: boolean) =>
    action(ActionTypes.SET_IS_EDIT_TRACK, value);
