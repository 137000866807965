import ActionTypes from "./constants";
import { ContainerActions, ContainerState } from "./types";
import { Customer } from "../models/Customer";
import { User } from "../models/User";
import { Geofence } from "../models/Geofence";
import { DrawModeEnum } from "../enums/DrawModeEnum";
import {CrudeModeEnum} from "../enums/CRUDModeEnum";
import {Vehicle} from "../models/Vehicle";
import { settings } from "../settings";

export const initialState: ContainerState = {
    languages: [],
    SERVER_CONNECTION_STATUS: 0,
    USER_LOGIN_STATUS: 0,
    INIT_SERVER_CONNECTION: false,
    currentUser: undefined,
    currentUserGeolocation: undefined,
    currentCustomer: undefined,
    vehicleList: [],
    vehicleListParams: undefined,
    vehicleId: undefined,
    vehicleTailList: [],
    vehicle: {},
    swipedVehicle: {},
    vehicleDriverList: [],
    tagList: [],
    vehicleTagParams: undefined,
    tag: undefined,
    deleteTagId: undefined,
    isOpenTags: false,
    isOpenGroups: false,
    loading: false,
    freeze: false,
    centered: false,
    expand: false,
    vehicleAddress: undefined,
    vehicleCurrentPosition: undefined,
    trackUUID: undefined,
    vehiclesTracks: [],
    trackParams: undefined,
    emptyTrack: false,
    visibleStream: false,
    visibleMonitoringInfo: false,
    lastPingTimestamp: undefined,
    geofenceList: [],
    geofenceListParams: undefined,
    geofenceId: undefined,
    geofence: {},
    geofence_CRUD_Mode: undefined,
    startDrawGeofenceType: undefined,
    startDrawGeofence: undefined,
    visibleGeofenceInfo: false,
    vehicleGeofenceList: [],
    editGeofenceId: undefined,
    isEditGeofence: false,
    vehicleListAttachedToGeofence: [],
    vehicleAmountInsideOutsideToGeofence: undefined,
    editedGeofenceRadius: undefined,
    checkedVehiclesPositions: [],
    checkedVehiclesPositionsParams: undefined,
    loadingVehiclesPosition: false,
    vehicleSensors: [],
    vehicleSensorsById: [],
    visibleTrackingInfo: false,
    trackSegmentUUID: undefined,
    trackSegmentEvent: undefined,
    followVehicleId: undefined,
    isVisibleGeozonesLayer: false,
    visibleVehicleList: false,
    visibleMobileMenuBtn: true,
    CRUD_Mode: CrudeModeEnum.VIEW,
    position: undefined,
    rawTrackForce: false,
    rawTrackPosition: undefined,
    geofenceEditableLayer: undefined,
    loadingFile: false,
    loadingFileName: undefined,
    importProgress: undefined,
    openImportList: false,
    importListInProgress: [],
    importHistory: [],
    drawMode: DrawModeEnum.DEFAULT,
    scrollPosition: [],
    pendingRefreshToken: false,
    accessibleCustomers: [],
    lastSelectedVehicleId: undefined,
    lastSelectedVehicleState: false
};

// Take this container's state (as a slice of root state), this container's actions and return new state
function sharedReducer(
    state: ContainerState = initialState,
    action: ContainerActions,
): ContainerState {
    switch (action.type) {
        case ActionTypes.CLEAR_GLOBAL_STATE: {
            const reset: any = {};
            for (const i in initialState) {
                if (state[i as keyof typeof state]) {
                    reset[i as keyof typeof reset] = initialState[i as keyof typeof initialState]
                }
            }
            return {
                ...reset
            }
        }
        case ActionTypes.SET_LANGUAGES:
            return {
                ...state,
                languages: action.payload,
            };
        case ActionTypes.SET_SERVER_CONNECTION_STATUS:
            return {
                ...state,
                SERVER_CONNECTION_STATUS: action.payload,
            };
        case ActionTypes.SET_INIT_SERVER_CONNECTION:
            return {
                ...state,
                INIT_SERVER_CONNECTION: action.payload,
            };
        case ActionTypes.SET_CURRENT_USER:
            return {
                ...state,
                currentUser: action.payload,
            };
        case ActionTypes.SET_CURRENT_USER_GEOLOCATION:
            return {
                ...state,
                currentUserGeolocation: action.payload,
            };
        case ActionTypes.UPDATE_CURRENT_USER_BY_ATTRIBUTE: {
            const { k, settings } = action.payload;
            const { currentUser } = state;
            if (currentUser && (k === "image" || k === "language" || k === "timezone" || k === "email" || k === "telegramId")) {
                currentUser[k as keyof User] = settings;
            } else if (currentUser && k === "phone") {
                if (currentUser[k as keyof User].length) currentUser[k as keyof User][0] = settings;
                else currentUser[k as keyof User] = [settings];
            } else {
                if (currentUser && !currentUser.attributes) currentUser.attributes = {};
                if (currentUser && currentUser.attributes) currentUser.attributes[k as keyof any] = settings;
            }
            return {
                ...state,
                currentUser: currentUser ? { ...currentUser } : undefined,
            };
        }
        case ActionTypes.SET_CURRENT_CUSTOMER:
            return {
                ...state,
                currentCustomer: action.payload,
            };
        case ActionTypes.UPDATE_CURRENT_CUSTOMER_BY_ATTRIBUTE: {
            const { key, attr } = action.payload;
            const { currentCustomer } = state;
            if (currentCustomer && (key === "winterStart" || key === "winterEnd" || key === "dailyDrivingLimit" || key === "offlineTimeout")) {
                if (currentCustomer && !currentCustomer?.attributes) currentCustomer.attributes = {};
                if (currentCustomer?.attributes && !currentCustomer.attributes?.general) currentCustomer.attributes.general = {};
                if (currentCustomer.attributes?.general) currentCustomer.attributes.general[key as keyof any] = attr;
            } else if (currentCustomer && (key === "maxSpeed" || key === "tripDistance" || key === "tripDuration" ||
                key === "stopMinDuration" || key === "stopMaxDuration" || key === "minFuelRefill" || key === "minFuelDrain")) {
                if (currentCustomer && !currentCustomer?.attributes) currentCustomer.attributes = {};
                if (currentCustomer?.attributes && !currentCustomer.attributes?.trips) currentCustomer.attributes.trips = {};
                if (currentCustomer.attributes?.trips) currentCustomer.attributes.trips[key as keyof any] = attr;
            } else if (currentCustomer) {
                currentCustomer[key as keyof Customer] = attr;
            }
            return {
                ...state,
                currentCustomer: currentCustomer ? { ...currentCustomer } : undefined,
            };
        }
        case ActionTypes.SET_VEHICLE_LIST:
            settings.updateCheckedVehicles(action.payload);
            return {
                ...state,
                vehicleList: action.payload,
            };
        case ActionTypes.UPDATE_VEHICLE: {
            const vehicle = action.payload;
            const updatedVehicles: Vehicle[] = state.vehicleList.map(item => {
                if (item.id === vehicle.id) {
                    return {...item, ...vehicle}
                }
                return item;
            });
            settings.updateCheckedVehicles(updatedVehicles);
            return {
                ...state,
                vehicleList: updatedVehicles
            };
        }
        case ActionTypes.UPDATE_VEHICLES: {
            const vehicles = action.payload;
            const updatedVehicles: Vehicle[] = state.vehicleList.map(item => {
                const vehicle = vehicles.find(it => it.id === item.id);
                if (vehicle) {
                    return {...item, ...vehicle}
                }
                return item;
            });
            settings.updateCheckedVehicles(updatedVehicles);
            return {
                ...state,
                vehicleList: updatedVehicles
            };
        }
        case ActionTypes.SET_VEHICLE_LIST_PARAMS:
            return {
                ...state,
                vehicleListParams: action.payload,
            };
        case ActionTypes.SET_VEHICLE_ID:
            return {
                ...state,
                vehicleId: action.payload,
            };
        case ActionTypes.SET_VEHICLES_TAILS:
            return {
                ...state,
                vehicleTailList: action.payload,
            };
        case ActionTypes.SET_VEHICLE:
            return {
                ...state,
                vehicle: action.payload,
            };
        case ActionTypes.SET_SWIPED_VEHICLE:
            return {
                ...state,
                swipedVehicle: action.payload,
            };
        case ActionTypes.SET_VEHICLE_DRIVERS:
            return {
                ...state,
                vehicleDriverList: action.payload,
            };
        case ActionTypes.SET_TAG_LIST:
            return {
                ...state,
                tagList: action.payload,
            };
        case ActionTypes.SET_VEHICLE_TAG_PARAMS:
            return {
                ...state,
                vehicleTagParams: action.payload,
            };
        case ActionTypes.SET_TAG:
            return {
                ...state,
                tag: action.payload,
            };
        case ActionTypes.SET_DELETE_TAG_ID:
            return {
                ...state,
                deleteTagId: action.payload,
            };
        case ActionTypes.SET_IS_OPEN_TAGS:
            return {
                ...state,
                isOpenTags: action.payload,
            };
        case ActionTypes.SET_IS_OPEN_GROUPS:
            return {
                ...state,
                isOpenGroups: action.payload,
            };
        case ActionTypes.SET_LOADING:
            return {
                ...state,
                loading: action.payload,
            };
        case ActionTypes.SET_FREEZE:
            return {
                ...state,
                freeze: action.payload,
            };
        case ActionTypes.SET_CENTERED:
            return {
                ...state,
                centered: action.payload,
            };
        case ActionTypes.SET_EXPAND:
            return {
                ...state,
                expand: action.payload,
            };
        case ActionTypes.SET_VEHICLE_ADDRESS:
            return {
                ...state,
                vehicleAddress: action.payload,
            };
        case ActionTypes.SET_VEHICLE_CURRENT_POSITION:
            return {
                ...state,
                vehicleCurrentPosition: action.payload,
            };
        case ActionTypes.SET_TRACK_UUID:
            return {
                ...state,
                trackUUID: action.payload,
            };
        case ActionTypes.SET_VEHICLES_TRACKS:
            return {
                ...state,
                vehiclesTracks: action.payload,
            };
        case ActionTypes.SET_TRACK_PARAMS:
            return {
                ...state,
                trackParams: action.payload,
            };
        case ActionTypes.SET_EMPTY_TRACK:
            return {
                ...state,
                emptyTrack: action.payload,
            };
        case ActionTypes.SET_VISIBLE_STREAM:
            return {
                ...state,
                visibleStream: action.payload,
            };
        case ActionTypes.SET_VISIBLE_MONITORING_INFO:
            return {
                ...state,
                visibleMonitoringInfo: action.payload,
            };
        case ActionTypes.SET_LAST_PING_TIMESTAMP:
            return {
                ...state,
                lastPingTimestamp: action.payload,
            };
        case ActionTypes.SET_GEOFENCE_LIST:
            settings.updateCheckedGeofences(action.payload);
            return {
                ...state,
                geofenceList: action.payload,
            };
        case ActionTypes.UPDATE_GEOFENCE: {
            const geofence = action.payload;
            const updatedGeofences: Geofence[] = state.geofenceList.map(item => {
                if (item.id === geofence.id) {
                    return {...item, ...geofence};
                    // item = {...item, ...geofence};
                }
                return item;
            });
            settings.updateCheckedGeofences(updatedGeofences);
            return {
                ...state,
                geofenceList: updatedGeofences,
            };
        }
        case ActionTypes.SET_GEOFENCE_LIST_PARAMS:
            return {
                ...state,
                geofenceListParams: action.payload,
            };
        case ActionTypes.SET_GEOFENCE_ID:
            return {
                ...state,
                geofenceId: action.payload,
            };
        case ActionTypes.SET_GEOFENCE:
            return {
                ...state,
                geofence: {...action.payload},
            };
        case ActionTypes.UPDATE_GEOFENCE_BY_ATTRIBUTE: {
            const { gKey, value } = action.payload;
            const { geofence } = state;
            if (gKey === "area" || gKey === "length" || gKey === "perimeter" || gKey === "buffer") {
                if (!geofence?.attributes) geofence.attributes = {};
                if (geofence?.attributes) geofence.attributes[gKey as keyof any] = value;
            } else if (geofence?.circle && gKey === "radius") {
                geofence.circle[gKey as keyof any] = value;
            } else if (geofence) geofence[gKey as keyof Geofence] = value;
            return {
                ...state,
                geofence: { ...geofence },
            };
        }
        case ActionTypes.SET_GEOFENCE_CRUD_MODE:
            return {
                ...state,
                geofence_CRUD_Mode: action.payload,
            };
        case ActionTypes.START_DRAW_GEOFENCE:
            return {
                ...state,
                startDrawGeofence: action.payload,
            };
        case ActionTypes.SET_VISIBLE_GEOFENCE_INFO:
            return {
                ...state,
                visibleGeofenceInfo: action.payload,
            };
        case ActionTypes.SET_VEHICLE_GEOFENCE_LIST:
            return {
                ...state,
                vehicleGeofenceList: action.payload,
            };
        case ActionTypes.UPDATE_VEHICLE_GEOFENCE: {
            const vehicle = action.payload;
            const updatedVehicles: Vehicle[] = state.vehicleGeofenceList.map(item => {
                if (item.id === vehicle.id) {
                    item = {...item, ...vehicle};
                }
                return item;
            });
            return {
                ...state,
                vehicleGeofenceList: updatedVehicles,
            };
        }
        case ActionTypes.UPDATE_VEHICLES_GEOFENCE: {
            const vehicles = action.payload;
            const updatedVehicles: Vehicle[] = state.vehicleGeofenceList.map(item => {
                const vehicle = vehicles.find(it => it.id === item.id);
                if (vehicle) {
                    return {...item, ...vehicle}
                }
                return item;
            });
            return {
                ...state,
                vehicleGeofenceList: updatedVehicles,
            };
        }
        case ActionTypes.START_EDIT_GEOFENCE_BY_ID:
            return {
                ...state,
                editGeofenceId: action.payload,
            };
        case ActionTypes.END_EDIT_GEOFENCE:
            return {
                ...state,
                isEditGeofence: action.payload,
            };
        case ActionTypes.SET_VEHICLE_LIST_ATTACHED_TO_GEOFENCE:
            return {
                ...state,
                vehicleListAttachedToGeofence: action.payload,
            };
        case ActionTypes.SET_VEHICLE_AMOUNT_INSIDE_OUTSIDE_TO_GEOFENCE:
            return {
                ...state,
                vehicleAmountInsideOutsideToGeofence: action.payload,
            };
        case ActionTypes.SET_EDITED_GEOFENCE_CIRCLE_RADIUS:
            return {
                ...state,
                editedGeofenceRadius: action.payload,
            };
        case ActionTypes.SET_CHECKED_VEHICLES_POSITIONS:
            return {
                ...state,
                checkedVehiclesPositions: action.payload,
            };
        case ActionTypes.SET_CHECKED_VEHICLES_POSITIONS_PARAMS:
            return {
                ...state,
                checkedVehiclesPositionsParams: action.payload,
            };
        case ActionTypes.SET_LOADING_VEHICLES_POSITIONS:
            return {
                ...state,
                loadingVehiclesPosition: action.payload,
            };
        case ActionTypes.SET_VEHICLE_SENSORS:
            return {
                ...state,
                vehicleSensors: action.payload,
            };
        case ActionTypes.SET_VEHICLE_SENSORS_BY_ID:
            return {
                ...state,
                vehicleSensorsById: action.payload,
            };
        case ActionTypes.SET_VISIBLE_TRACKING_INFO:
            return {
                ...state,
                visibleTrackingInfo: action.payload,
            };
        case ActionTypes.SET_TRACK_SEGMENT_UUID:
            return {
                ...state,
                trackSegmentUUID: action.payload,
            };
        case ActionTypes.SET_TRACK_SEGMENT_EVENT:
            return {
                ...state,
                trackSegmentEvent: action.payload,
            };
        case ActionTypes.SET_FOLLOW_VEHICLE_ID:
            return {
                ...state,
                followVehicleId: action.payload,
            };
        case ActionTypes.SET_VISIBLE_GEOZONES_LAYER:
            return {
                ...state,
                isVisibleGeozonesLayer: action.payload,
            };
        case ActionTypes.SET_VISIBLE_VEHICLE_LIST:
            return {
                ...state,
                visibleVehicleList: action.payload,
            };
        case ActionTypes.SET_VISIBLE_MOBILE_MENU_BTN:
            return {
                ...state,
                visibleMobileMenuBtn: action.payload,
            };
        case ActionTypes.SET_CRUD_MODE:
            return {
                ...state,
                CRUD_Mode: action.payload,
            };
        case ActionTypes.SET_POSITION:
            return {
                ...state,
                position: action.payload,
            };
        case ActionTypes.SET_RAW_TRACK_FORCE:
            return {
                ...state,
                rawTrackForce: action.payload,
            };
        case ActionTypes.SET_RAW_TRACK_POSITION:
            return {
                ...state,
                rawTrackPosition: action.payload,
            };
        case ActionTypes.SET_GEOFENCE_EDITABLE_LAYER:
            return {
                ...state,
                geofenceEditableLayer: action.payload,
            };
        case ActionTypes.LOADING_FILE:
            return {
                ...state,
                loadingFile: action.payload,
            };
        case ActionTypes.LOADING_FILE_NAME:
            return {
                ...state,
                loadingFileName: action.payload,
            };
        case ActionTypes.SET_IMPORT_PROGRESS:
            return {
                ...state,
                importProgress: action.payload,
            };
        case ActionTypes.OPEN_IMPORT_LIST:
            return {
                ...state,
                openImportList: action.payload,
            };
        case ActionTypes.SET_IMPORT_LIST_IN_PROGRESS:
            return {
                ...state,
                importListInProgress: action.payload,
            };
        case ActionTypes.SET_IMPORT_HISTORY:
            return {
                ...state,
                importHistory: action.payload,
            };
        case ActionTypes.SET_DRAW_MODE:
            return {
                ...state,
                drawMode: action.payload,
            };
        case ActionTypes.SET_SCROLL_POSITION: {
            const scrollPosition = state?.scrollPosition?.filter(item => item.type !== action.payload?.type);
            return {
                ...state,
                scrollPosition: [...scrollPosition, action.payload],
            };
        }
        case ActionTypes.SET_ACCESSIBLE_CUSTOMERS:
            return {
                ...state,
                accessibleCustomers: action.payload,
            };
        case ActionTypes.SET_USER_LOGIN_STATUS:
            return {
                ...state,
                USER_LOGIN_STATUS: action.payload,
            };
        case ActionTypes.SET_LAST_SELECTED_VEHICLE_ID:
            return {
                ...state,
                lastSelectedVehicleId: action.payload,
            };
        case ActionTypes.SET_LAST_SELECTED_VEHICLE_STATE:
            return {
                ...state,
                lastSelectedVehicleState: action.payload,
            };
        default:
            return state;
    }
}

export default sharedReducer;